import * as React from 'react';
import { get } from 'lodash';
import { graphql } from 'gatsby';
import styled from 'react-emotion';

import Container from '../components/Container';
import ImageContainer from '../components/ImageContainer';
import IndexLayout from '../layouts';
import Page from '../components/Page';

import { fluidImage as fluidImageFragment } from '../queries/fragments';

import { colors, fonts } from '../styles/variables';

import { useWindowDimensions } from '../util/hooks';

interface DocsProps {
	data: {
		natchem: Node,
		resume: Node,
	};
}

const StyledAnchor = styled.a`
	justify-contents: center;
	align-items: flex-start;
	color: ${colors.light};
	display: flex;
	font-family: ${fonts.monospace};
	font-size: 30px;
	font-weight: 600;
	padding: 20px;
	overflow: hidden;
	text-overflow: ellipsis;
	&:hover,
	&:focus {
		color: ${colors.lightAccent};
		font-weight: 600;
		text-decoration: none;
	}
`;

const Row = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 10px;
`;

const Col = styled.div`
	align-items: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 20px;
`;

const Docs = (props: DocsProps) => {
	const { width } = useWindowDimensions();
	const containerWidth = width * 0.82;
	// const resumeSrc = get(props, 'data.resume.publicURL');
	const natchemSrc = get(props, 'data.natchem.publicURL');
	// const resumePreviewFluid = get(props, 'data.resume_preview.childImageSharp.fluid');
	const natchemPreviewFluid = get(props, 'data.natchem_preview.childImageSharp.fluid');

	// const resumePreview = !!resumePreviewFluid && (
	// 	<StyledAnchor href={resumeSrc}>
	// 		<ImageContainer fluid={resumePreviewFluid} width={0.8 * Number(containerWidth)} />
	// 	</StyledAnchor>
	// );

	const natChemPreview = !!natchemPreviewFluid && (
		<StyledAnchor href={natchemSrc}>
			<ImageContainer fluid={natchemPreviewFluid} width={0.8 * Number(containerWidth)} />
		</StyledAnchor>
	);
	return (
		<IndexLayout>
			<Page>
				<Container>
					<Row>
						{/* {resumeSrc && (
							<Col>
								<StyledAnchor href={resumeSrc}>Resume</StyledAnchor>
								{resumePreview}
							</Col>
						)} */}
						{natchemSrc && (
							<Col>
								<StyledAnchor href={natchemSrc}>
									An Enzyme-Coupled Biosensor...
								</StyledAnchor>
								{natChemPreview}
							</Col>
						)}
					</Row>
				</Container>
			</Page>
		</IndexLayout>
	);
};

export default Docs;

export const fluidImage = fluidImageFragment;
export const pageQuery = graphql`
	query {
		natchem: file(relativePath: { regex: "/Biology.pdf/" }) {
			id
			relativePath
			sourceInstanceName
			publicURL
		}
		natchem_preview: file(relativePath: { regex: "/previews/natchem_preview.png/" }) {
			...fluidImage
		}
		resume: file(relativePath: { regex: "/Engineer.pdf/" }) {
			id
			relativePath
			sourceInstanceName
			publicURL
		}
		resume_preview: file(relativePath: { regex: "/previews/resume_preview.png/" }) {
			...fluidImage
		}
	}
`;
