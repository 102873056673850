import * as React from 'react';
import Img, { FluidObject } from 'gatsby-image';

import styled from 'react-emotion';

interface ImageProps {
	width: number;
}

const Container = styled.div`
	max-width: 600px;
	width: ${({ width }: ImageProps) => width}px;
`;

interface ImageContainerProps {
	fluid: FluidObject;
	width: number;
}

const ImageContainer: React.FunctionComponent<ImageContainerProps> = ({ fluid, width }) => {
	return (
		<Container width={width}>
			<Img fluid={fluid} />
		</Container>
	);
};

export default ImageContainer;
